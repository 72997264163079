import { Divider } from "antd";
import StaticDisplay from "./StaticDisplay";
import DynamicDisplay from "./DynamicDisplay/DynamicDisplay";

const DataDisplay = ({ data, setData }) => {
  const bbox_area = data.bboxes
    .map((bbox) => bbox.height * bbox.width)
    .reduce((a, b) => a + b);

  return (
    <>
      <StaticDisplay data={data} bbox_area={bbox_area} />
      <Divider />
      <DynamicDisplay data={data} bbox_area={bbox_area} setData={setData} />
    </>
  );
};

export default DataDisplay;
