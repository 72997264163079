const Base64ImagePreview = ({ image }) => {
  return (
    <img
      style={{ maxWidth: "100%", maxHeight: "120px" }}
      src={"data:image/jpeg;base64," + image}
      alt="DXF file"
    />
  );
};

export default Base64ImagePreview;
