import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import {  Spin, Upload } from "antd";
import axios from "axios";

const { Dragger } = Upload;

const props = {
  name: "file",
  multiple: false,
  accept: ".dxf",
  fileList: [],
};

const DxfUploader = ({ loading, setLoading, setError, setData }) => {
  return (
    <Dragger
      {...props}
      disabled={loading}
      customRequest={async (options) => {
        try {
          setLoading(true);
          const response = await axios.post(
            "/predict",
            {
              file: options.file,
            },
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          setData(response.data);
          setError(null);
        } catch (err) {
          setError(err);
          setData(null);
        } finally {
          setLoading(false);
        }
      }}
    >
      {loading ? (
        <p className="ant-upload-drag-icon">
          <Spin size="large" />
        </p>
      ) : (
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Support for a single upload only.</p>
        </>
      )}
    </Dragger>
  );
};

export default DxfUploader;
