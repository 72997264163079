const MATERIALS = {
  Stainless: {
    Matte: {
      "1mm": 0.001565503131,
      "1.2mm": 0.00186000372,
      "1.5mm": 0.002278504557,
      "2mm": 0.003022506045,
      "3mm": 0.004510509021,
      "4.5mm": 0.006339512679,
      "6mm": 0.008494016988,
      "8mm": 0.01178002356,
      "10mm": 0.013872527745,
      "12mm": 0.015236530473,
    },
    Brushed: {
      "1mm": 0.001720503441,
      "1.2mm": 0.00201500403,
      "1.5mm": 0.002464504929,
      "2mm": 0.003239506479,
      "3mm": 0.004758509517,
      "4.5mm": 0.007238514477,
      "6mm": 0.009408518817,
      "8mm": 0.012694525389,
      "10mm": 0.014771529543,
      "12mm": 0.016151032302,
    },
    Shiny: {
      "1mm": 0.002418004836,
      "1.2mm": 0.002728005456,
      "1.5mm": 0.003193006386,
      "2mm": 0.004138508277,
      "3mm": 0.005704011408,
      "4.5mm": 0.008757517515,
      "6mm": 0.010912021824,
      "8mm": 0.014213528427,
      "10mm": 0.016290532581,
      "12mm": 0.017654535309,
    },
  },
  "Stainless 316": {
    Matte: {
      "1mm": 0.002697005394,
      "1.2mm": 0.003131006262,
      "1.5mm": 0.003937007874,
      "2mm": 0.005146010292,
      "3mm": 0.007967015934,
      "4.5mm": 0.011206522413,
      "6mm": 0.01488002976,
      "8mm": 0.020708041416,
      "10mm": 0.02712505425,
      "12mm": 0.031883563767,
    },
    Brushed: {
      "1mm": 0.003224006448,
      "1.2mm": 0.003735507471,
      "1.5mm": 0.004355508711,
      "2mm": 0.005766011532,
      "3mm": 0.00868001736,
      "4.5mm": 0.012105524211,
      "6mm": 0.015779031558,
      "8mm": 0.021622543245,
      "10mm": 0.028039556079,
      "12mm": 0.032798065596,
    },
    Shiny: {
      "1mm": 0.00418500837,
      "1.2mm": 0.004743009486,
      "1.5mm": 0.005394010788,
      "2mm": 0.006804513609,
      "3mm": 0.009641019282,
      "4.5mm": 0.013624527249,
      "6mm": 0.017298034596,
      "8mm": 0.023126046252,
      "10mm": 0.029543059086,
      "12mm": 0.034317068634,
    },
  },
  Titanium: {
    Gold: {
      "1mm": 0.003224006448,
      "1.2mm": 0.00434000868,
      "1.5mm": 0.007734515469,
    },
    "Orange Gold": {
      "1mm": 0.004433008866,
      "1.2mm": 0.004851509703,
      "1.5mm": 0.009424018848,
    },
    Copper: {
      "1mm": 0.005549011098,
      "1.2mm": 0.008075516151,
      "1.5mm": 0.009424018848,
    },
    Bronze: {
      "1mm": 0.005657511315,
      "1.2mm": 0.008075516151,
      "1.5mm": 0.009424018848,
    },
    Black: {
      "1mm": 0.006153512307,
      "1.2mm": 0.00666501333,
      "1.5mm": 0.009424018848,
    },
  },
  Copper: {
    Matte: {
      "1mm": 0.007393514787,
      "1.2mm": 0.008742017484,
      "1.5mm": 0.010431520863,
      "2mm": 0.014120528241,
      "3mm": 0.022599045198,
      "4.5mm": 0.033294066588,
      "6mm": 0.03983507967,
      "10mm": 0.066402132804,
    },
    Brushed: {
      "1mm": 0.007998015996,
      "1.2mm": 0.009346518693,
      "1.5mm": 0.011036022072,
      "2mm": 0.014740529481,
      "3mm": 0.023203546407,
      "4.5mm": 0.033914067828,
      "6mm": 0.040439580879,
      "10mm": 0.067006634013,
    },
    Shiny: {
      "1mm": 0.009021018042,
      "1.2mm": 0.010354020708,
      "1.5mm": 0.012043524087,
      "2mm": 0.015748031496,
      "3mm": 0.024226548453,
      "4.5mm": 0.034921569843,
      "6mm": 0.041447082894,
      "10mm": 0.068014136028,
    },
  },
  Brass: {
    Matte: {
      "1mm": 0.007672515345,
      "1.2mm": 0.008416516833,
      "1.5mm": 0.009951019902,
      "2mm": 0.01224502449,
      "3mm": 0.020584041168,
      "4.5mm": 0.033774567549,
      "6mm": 0.040362080724,
      "10mm": 0.066402132804,
    },
    Brushed: {
      "1mm": 0.008277016554,
      "1.2mm": 0.009021018042,
      "1.5mm": 0.010555521111,
      "2mm": 0.012849525699,
      "3mm": 0.021188542377,
      "4.5mm": 0.034379068758,
      "6mm": 0.040966581933,
      "10mm": 0.067006634013,
    },
    Shiny: {
      "1mm": 0.009284518569,
      "1.2mm": 0.010028520057,
      "1.5mm": 0.011578523157,
      "2mm": 0.013857027714,
      "3mm": 0.022196044392,
      "4.5mm": 0.035386570773,
      "6mm": 0.041974083948,
      "10mm": 0.068014136028,
    },
  },
  Aluminum: {
    Matte: {
      "1mm": 0.001116002232,
      "2mm": 0.00124000248,
      "3mm": 0.00186000372,
      "4.5mm": 0.003239506479,
      "6mm": 0.004309008618,
      "10mm": 0.01302002604,
      "12mm": 0.016151032302,
    },
    Brushed: {
      "1mm": 0.001519003038,
      "2mm": 0.002154504309,
      "3mm": 0.002774505549,
      "4.5mm": 0.004154008308,
      "6mm": 0.005223510447,
      "10mm": 0.013934527869,
      "12mm": 0.017065534131,
    },
    Shiny: {
      "1mm": 0.001984003968,
      "2mm": 0.003658007316,
      "3mm": 0.004278008556,
      "4.5mm": 0.005657511315,
      "6mm": 0.006727013454,
      "10mm": 0.015453530907,
      "12mm": 0.018584537169,
    },
  },
};

export default MATERIALS;
