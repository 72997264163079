import { Select } from "antd";
import UNITS from "../../../const/units";

const UnitSelect = ({ data, setData }) => {

    const onChange = (newUnit) => {
        setData({
            ...data,
            unit: newUnit,
            conversion_factor: UNITS.find((e) => e.name === newUnit).conversion_factor,
        })
    }

    return (
        <Select onChange={onChange} value={data.unit}>
            {UNITS.map((u) => <Select.Option value={u.name}>{u.name}</Select.Option>)}
        </Select>
    );
}

export default UnitSelect;