const UNITS = [
    {
        "name": "Millimeters",
        "conversion_factor": 1.0,
    },
    {
        "name": "Inches",
        "conversion_factor": 25.400000000101603,
    }
];

export default UNITS;