import { Descriptions, Space } from "antd";
import Base64ImagePreview from "./Base64ImagePreview";

const StaticDisplay = ({ data, bbox_area }) => {
  const unit = data.unit;

  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      <Descriptions title="Preview">
        <Descriptions.image>
          <Base64ImagePreview image={data.image} />
        </Descriptions.image>
      </Descriptions>
      <Descriptions title="Info">
        <Descriptions.Item label="Bounding box area">
          {bbox_area.toFixed(2)} {unit}^2
        </Descriptions.Item>
        <Descriptions.Item label="Dimension (WxH)">
          {data.dimension.width.toFixed(2)} {unit} x{" "}
          {data.dimension.height.toFixed(2)} {unit}
        </Descriptions.Item>
        <Descriptions.Item label="Item count">
          {data.items_count}
        </Descriptions.Item>
        <Descriptions.Item label="Perimeter">
          {data.perimeter.toFixed(2)} {unit}
        </Descriptions.Item>
        <Descriptions.Item label="Surface area">
          {data.surface_area.toFixed(2)} {unit}^2
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
};

export default StaticDisplay;
