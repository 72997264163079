import { Checkbox, Form, InputNumber, Space } from "antd";
import { useState } from "react";
import MaterialSelect from "./MaterialSelect";
import UnitSelect from "./UnitSelect";

const FIXTURES = {
  rivet: "rivet",
  tape: "tape",
  none: null,
};

const DynamicDisplay = ({ data, setData, bbox_area }) => {
  // Don't want to use form because I want the data to be update instantly
  const [materialPrice, setMaterialPrice] = useState(0);
  const [fixture, setFixture] = useState(FIXTURES.none);
  const [depth, setDepth] = useState(0);

  const unit = data.unit;

  const laborCostPerPerimeter = 0.2755905512; // 7 baht / inch

  // calculate separately in case it need to be shown in the future
  const frontPrice = bbox_area * materialPrice * data.conversion_factor ** 2;
  const sidePrice =
    depth * data.perimeter * materialPrice * data.conversion_factor ** 2;
  const laborPrice =
    data.perimeter * laborCostPerPerimeter * data.conversion_factor;
  const rivetPrice = 20 * 3 * data.items_count; // 3 rivets per object
  const tapePrice = 15 * data.items_count;

  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      <Form title="Input" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="Units">
          <UnitSelect data={data} setData={setData} />
        </Form.Item>
        <Form.Item label="Material">
          <MaterialSelect setMaterialPrice={setMaterialPrice} />
        </Form.Item>
        <Form.Item label="Return">
          <InputNumber precision={2} min={0} onChange={setDepth} />
          &nbsp;&nbsp;{unit}
        </Form.Item>
        <Form.Item label="Rivet">
          <Checkbox
            checked={fixture === FIXTURES.rivet}
            onChange={(e) =>
              setFixture(e.target.checked ? FIXTURES.rivet : FIXTURES.none)
            }
          />
        </Form.Item>
        <Form.Item label="Tape">
          <Checkbox
            checked={fixture === FIXTURES.tape}
            onChange={(e) =>
              setFixture(e.target.checked ? FIXTURES.tape : FIXTURES.none)
            }
          />
        </Form.Item>
        <Form.Item label="Price">
          <b>
            {(
              frontPrice +
              sidePrice +
              laborPrice +
              (fixture === FIXTURES.rivet ? rivetPrice : 0) +
              (fixture === FIXTURES.tape ? tapePrice : 0)
            ).toFixed(2)}
          </b>
        </Form.Item>
      </Form>
    </Space>
  );
};

export default DynamicDisplay;
