import { useState } from "react";
import MATERIALS from "../../../const/materials";
import { TreeSelect } from "antd";

const MaterialSelect = ({ setMaterialPrice }) => {
  const [expandedKeys, setExpandedKeys] = useState([]);

  const toggleExpandNode = (key) => {
    setExpandedKeys((prev) => {
      const outArr = [...prev];
      if (outArr.includes(key)) {
        return outArr.filter((e) => e !== key);
      } else {
        outArr.push(key);
        return outArr;
      }
    });
  };

  const _full_size_span = (key, title) => (
    <span
      onClick={() => toggleExpandNode(key)}
      style={{ width: "100%", height: "100%", display: "inline-block" }}
    >
      {title}
    </span>
  );

  const _to_items = (materials) =>
    Object.entries(materials).map(([mat, finishes]) => ({
      value: mat,
      title: _full_size_span(mat, mat),
      selectable: false,
      children: Object.entries(finishes).map(([finish, thicknesses]) => ({
        value: `${mat}::${finish}`,
        title: _full_size_span(`${mat}::${finish}`, `${mat} - ${finish}`),
        selectable: false,
        children: Object.entries(thicknesses).map(([thickness, price]) => ({
          value: `${mat}::${finish}::${thickness}`,
          title: `${mat} - ${finish} - ${thickness}`,
        })),
      })),
    }));

  return (
    <TreeSelect
      onTreeExpand={setExpandedKeys}
      treeExpandedKeys={expandedKeys}
      treeData={_to_items(MATERIALS)}
      onSelect={(material) => {
        const [mat, finish, thickness] = material.split("::");
        setMaterialPrice(MATERIALS[mat][finish][thickness]);
      }}
      treeExpandAction={true}
    />
  );
};

export default MaterialSelect;
